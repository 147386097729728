<template>
  <div class="log">
    <div class="login">
      <div class="login-width">
        <div class="login-center-width">
          <div class="register-width-center-img">
            <Isvg icon-class="logo-text" class-name="item-logo" />
          </div>
          <div v-show="showLogin">
            <ValidationObserver ref="regForm">
              <ValidationProvider rules="required|mobile" name="手机号" v-slot="{ errors }" mode="passive">
                <div class="content">
                  <input class="input-400 login-input-heightbot login-input-block" type="text" v-model="mobile"
                         placeholder="请输入手机号" autofocus="autofocus"/>
                  <p>{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider rules="required" name="密码" v-slot="{ errors }" mode="passive">
                <div class="content">
                  <Isvg icon-class="eye-close" class-name="visible-password" @click.native="showPas" v-if="pas == 1"/>
                  <Isvg icon-class="eye-open" class-name="invisible-password" @click.native="blankPas" v-if="pas == 2"/>
                  <input ref="showPas" class="input-400 login-input-heightbot login-input-block" type="password"
                         v-model="password" placeholder="请设置密码6-16位字符"/>
                  <p>{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider rules="required" name="验证码" v-slot="{ errors }" mode="passive">
                <div class="content1">
                  <p>
                    <input class="input-250 login-input-heightbot login-input-block" type="text" placeholder="请输入验证码"
                           v-model="code"/>
                    <button :class="'button-blue-150' + btnStyle" @click="getSendCode(this)" ref="buttonNo">
                      {{ codeContent }}
                    </button>
                  </p>
                  <p class="p">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>

              <div class="yuedu">
                <p style="font-size: 12px">
                  <input type="checkbox" v-model="accept"/>
                  <strong>我已阅读并接受</strong>
                  <a class="b ml-5 mr-5" href="http://www.gogowork.cn/help/info/6253db653e5476238bc23eb22681f53c" target="_bank">用户协议</a>
                  <!-- <el-button class="b ml-5 mr-5" :disabled="isCheck" @click="goUserAgreement" type="text"></el-button> -->
                  <strong>和</strong>
                  <!-- <el-button type="text" :disabled="isCheck" @click="goUserPrivacy" class="ml-5"></el-button> -->
                  <a class="b ml-5 cu" href="http://www.gogowork.cn/help/info/6253d217d47f4539c7b42b85af60cfdc" target="_bank">隐私政策</a>
                </p>
                <span v-show="acceptMsg">请先同意《用户协议》以及《隐私协议》</span>
              </div>
            </ValidationObserver>
            <el-button type="button" :loading="regLoading" class="button-blue-400 register-button f" @click="toReg()">
              注册
            </el-button>
            <div class="hui"></div>
            <div class="register-zhanghao">
              <!-- <li>使用社交账号注册</li>
              <img src="@/icons/svg/login/微信24X24.svg" alt="" />
              <img src="@/icons/svg/login/微博24X24.svg" alt="" />
              <img src="@/icons/svg/login/QQ24X24.svg" alt="" /> -->
              <svg class="icon" aria-hidden="true" @click="showWechate">
                <use xlink:href="#icon-WeChat"></use>
              </svg>
            </div>
            <p class="register-goLogin">
              已有账号？
              <a href="javascript:void(0)" @click="$router.push('/login')">马上登录</a>
            </p>
          </div>
        </div>
        <CheckWechat v-if="LWhate"></CheckWechat>
      </div>
      <p class="register-beihao fs" style="font-size: 10px">© 2022 gogowork.cn All Rights Reserved
        京IPC备：2021019219号-1</p>
    </div>
  </div>
</template>

<script>
import {requestReg, requestSendCode} from '@/api/index';
import LoginHeader from "@/components/LoginHeader"
import CheckWechat from "@/components/CheckWechat"

export default {
  name: 'register',
  components: {
    CheckWechat
  },
  data() {
    return {
      // 手机号码
      mobile: '',
      // 密码
      password: '',
      // 验证码
      code: '',
      // 同意协议
      accept: false,
      acceptMsg: false,
      // 密码显示与隐藏
      pas: 1,

      wait: 60,
      codeContent: '发送验证码',
      canClick: true,
      btnStyle: '',
      regLoading: false,

      LWhate: false,
      showLogin: true,
    };
  },
  created() {
    if (this.$root.isLogin) {
      this.$router.push('/home/page');
    }
    this.keyDown()
  },
  mounted() {
    this.$bus.$on('toLogins', () => {
      this.LWhate = false;
      this.showLogin = true;
    })
  },
  methods: {
    /* 显示微信弹框 */
    showWechate() {
      this.LWhate = true;
      this.showLogin = false
    },
    toReg() {
      this.$refs.regForm.validate().then(success => {
        if (!success) {
          return;
        } else {
          if (!this.accept) {
            this.acceptMsg = true; // 请先勾选接受协议
            this.$notify({
              title: '警告',
              message: '请先勾选接受协议',
              type: 'error'
            });
          } else {
            this.reg();
          }
        }
      });
    },
    async reg() {
      let data = {
        mobile: this.mobile,
        password: this.password,
        code: this.code
      };

      const res = await requestReg(data);
      if (res.statusCode == 200) {
        this.regLoading = true;
        this.$notify({
          message: '注册已完成...',
          type: 'success'
        });
        this.$store.dispatch('useUserStore/setLoginStatusInfo', res.data)
        this.$router.push('/identity');
      } else {
        this.$notify({
          message: res.message,
          type: 'warning'
        });
      }
    },
    getSendCode(obj) {
      if (this.canClick) {
        // 单独验证手机号
        this.$validate(this.mobile, 'required|mobile', {
          name: '手机号',
        }).then(result => {
          if (result.valid) {
            this.canClick = false;
            // 开始倒计时
            this.$refs.buttonNo.style = 'cursor: not-allowed';
            this.btnStyle = ' disabled';
            this.codeContent = this.wait + '秒';
            let clock = window.setInterval(() => {
              this.wait--;
              this.codeContent = this.wait + '秒';
              if (this.wait < 0) {
                //当倒计时小于0时清除定时器
                window.clearInterval(clock);
                this.codeContent = '重新发送验证码';
                this.wait = 60;
                this.canClick = true;
                this.btnStyle = '';
                this.$refs.buttonNo.style = 'cursor: pointer';
              }
            }, 1000);
            this.sendCode();
          } else {
            this.$notify({
              message: '手机号格式错误',
              type: 'error'
            });
          }
        });
      }
    },
    async sendCode() {
      const res = await requestSendCode({account: this.mobile, code_type: 1});
      if (res.statusCode == 200) {
        this.$notify({
          message: res.data,
          type: 'success'
        });
      } else {
        this.$notify({
          message: res.message,
          type: 'warn'
        });
      }
    },
    checkMobile(mobile) {
      if (mobile == '') {
        this.$notify({
          message: '请填写正确的手机号码',
          type: 'error'
        });
        return false;
      }
      // 必须是1开头，第二位数字可以是0-9任意一个，总长为11
      let reg = /^1([0-9])\d{9}$/;
      if (reg.test(mobile)) {
        return true;
      } else {
        return false;
      }
    },
    // 显示密码
    showPas() {
      this.pas = 2;
      this.$refs.showPas.type = 'text';
    },
    // 隐藏密码
    blankPas() {
      this.pas = 1;
      this.$refs.showPas.type = 'password';
    },
    /* goUserAgreement(){
      window.open("http://www.gogowork.cn/help/info/6253db653e5476238bc23eb22681f53c");
    },
    goUserPrivacy(){
      window.open("http://www.gogowork.cn/help/info/6253d217d47f4539c7b42b85af60cfdc");
    } */
    //按钮监听键盘
    keyDown () {
      let that = this
      //监听键盘按钮
      document.onkeydown = function (event) {
        var e = event || window.event;
        var keyCode = e.keyCode || e.which;
        switch (keyCode) {
          case 13://enter
            that.toReg()
            break;
          default:
            break;
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.item-logo{
  width: 8em;
  height: 3em;
  margin-top: 20px;
}
.login{
  padding-top: 60px;
}
.register-width-center-img {
  text-align: center;
  margin-bottom: 40px;

  img {
    width: 162px;
    height: 32px;
    margin-top: 40px;
    object-fit: fill;
  }
}

h2 {
  text-align: center;
  font-size: 18px;
  text-align: center;
  margin: 20px 0 40px 0;
}

.content {
  position: relative;
  width: 300px;
  margin-bottom: 20px;

  .capptalize {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 38px;
    top: 10px;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    cursor: pointer;
  }

  .visible-password {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    cursor: pointer;
  }

  .invisible-password {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    cursor: pointer;
  }

  p {
    font-size: 10px;
    color: #e4007f;
    margin-left: 10px;
  }
}

.content1 {
  margin-bottom: 20px;

  p {
    width: 300px;
    display: flex;
  }

  .p {
    font-size: 10px;
    color: #e4007f;
    margin-left: 10px;
  }
}

.yuedu {
  margin-top: -6px;

  p {
    width: 300px;
    margin-bottom: 6px;

    input {
      width: 12px;
      height: 12px;
      margin-right: 10px;
      cursor: pointer;
    }

    strong,
    b {
      font-size: 12px;
      font-weight: 400;
      color: #575757;
    }

    b {
      color: #00a2e6;
      margin: 0 5px;
      cursor: pointer;
    }
  }

  span {
    font-size: 10px;
    font-weight: 400;
    color: #e7208f;
    position: relative;
    left: 22.5px;
    top: -6px;
  }
}

/* 此处的是为了和上面的20高度对齐 */
.register-button {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  /deep/ span {
    position: relative;
    top: 1px;
  }

  margin-top: 14px;
}

/* register样式 */
</style>
